<div class="row mb-3">
  <app-input-label label="Column Headers" hint="Configure the columns for the table by specifying fields.">
  </app-input-label>
  @for (control of inputs.controls; track control; let i = $index) {
  <div class="col-md-4 mt-2">
    <div class="row">
      <div class="col-md-8">
        <app-input-basic name="header" (change)="setColumns()" [form]="control" />
      </div>
      <div class="col-md-2">
        <app-btn [group]="'add'" (click)="addControl(i)"/>
      </div>
      <div class="col-md-2">
        <app-btn [group]="'delete'" (click)="removeColumn(i)"/>
      </div>
    </div>
  </div>
}
</div>

<table-plain [displayedColumns]="dCols" [obsDataSource]="rows"></table-plain>

<div class="row mt-3 justify-content-end">
  <div class="col-md-4">
    <app-btn [text]="'Add Content'" type="primary" (click)="addRow()"/>
  </div>
</div>
