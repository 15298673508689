import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewTableComponent } from '../view-table/view-table.component';
import { CreateTableComponent } from '../create-table/create-table.component';
import { QuillViewComponent } from 'ngx-quill';

import { MatFormFieldModule } from '@angular/material/form-field';
import { CellInputComponent } from '../cell-input/cell-input.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from '@angular/common';
import { RichTextEditorRFComponent } from 'ets-fe-ng-sdk';

@Component({
  selector: 'app-doc-cell-input',
  templateUrl: './doc-cell-input.component.html',
  styleUrls: ['./doc-cell-input.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    CellInputComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RichTextEditorRFComponent,
    QuillViewComponent,
    CreateTableComponent,
    ViewTableComponent,
    NgSwitchDefault,
    UpperCasePipe,
  ],
})
export class DocCellInputComponent {
  @Input() type: 'TEXT' | 'TABLE' | 'UPLOAD' = 'TEXT';
  @Input() control: AbstractControl;
  @Input() index: number;
  @Input() tableConfig: AbstractControl;
  @Input() edit: boolean = true;

  get form() {
    return this.controlContent as FormGroup;
  }

  get controlContent() {
    return this.control.get('content');
  }

  get config() {
    return this.controlContent.get('config');
  }

  /**
   * Get Formgroup to append
   * @param type
   * @returns Form group
   */
  content(type: 'TEXT' | 'TABLE' | 'UPLOAD') {
    return new FormGroup({
      contentType: new FormControl(type),
      content: new FormGroup({
        config: new FormControl(),
        content: new FormControl(),
      }),
    });
  }
}
