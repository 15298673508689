import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { BtnService } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'jupiter-cell',
    templateUrl: './cell-input.component.html',
    styleUrls: ['./cell-input.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        BtnComponent,
        NgClass,
    ],
})
export class CellInputComponent {
  @Input() collapsed = false;
  @Input() control: AbstractControl;
  @Input() content: (contentType?: 'TABLE' | 'TEXT' | 'UPLOAD') => FormGroup<{
    contentType: FormControl<'TABLE' | 'TEXT' | 'UPLOAD'>;
    content: FormGroup<{
      config: FormControl<any>;
      content: FormControl<any>;
    }>;
  }>;
  @Input() edit: boolean = true;
  @Input() index: number;
  @Output() collapsedChange = new EventEmitter<boolean>();

  editing: boolean = true;

  constructor(public btnS: BtnService) {}

  /**
   * Add cell above
   * @param type Type of cell
   */
  prependCell(type: 'TEXT' | 'TABLE' | 'UPLOAD') {
    const parent = this.control.parent as FormArray;
    parent.insert(this.index, this.content(type));
  }

  /**
   * Add cell below
   * @param type Type of cell
   */
  appendCell(type: 'TEXT' | 'TABLE' | 'UPLOAD') {
    const parent = this.control.parent as FormArray;
    parent.insert(this.index + 1, this.content(type));
  }

  /**
   * Delete cell
   * @param index Cell index
   */
  deleteCell(index: number) {
    const parent = this.control.parent as FormArray;
    parent.removeAt(index);
  }
}
