@switch (type) {
  @case ('TEXT') {
    <jupiter-cell [collapsed]="false" [control]="control" [edit]="edit" [content]="content" [index]="index">
      <div [formGroup]="form" jupiter-cell-input>
        <span>{{ type | uppercase }}</span>
        <rich-text-editor-rf
          [showLabel]="false"
          #matEditor
          [form]="form"
          name="content"></rich-text-editor-rf>
      </div>
      <div jupiter-cell-output>
        <quill-view [content]="matEditor.valueSignal()"></quill-view>
      </div>
    </jupiter-cell>
  }
  @case ('TABLE') {
    <jupiter-cell [collapsed]="false" [edit]="edit" [control]="control" [content]="content" [index]="index">
      <div jupiter-cell-input>
        <span>{{ type | uppercase }}</span>
        <app-create-table
          [control]="controlContent.get('content')"
          [returnedFields]="config"></app-create-table>
      </div>
      <div jupiter-cell-output>
        <app-view-table [config]="config" [data]="controlContent.value.content"></app-view-table>
      </div>
    </jupiter-cell>
  }
  @default {
    <jupiter-cell [collapsed]="false" [edit]="edit" [control]="control" [content]="content" [index]="index">
      <div jupiter-cell-input>//Default</div>
      <div jupiter-cell-output>//Default</div>
    </jupiter-cell>
  }
}
