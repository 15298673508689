<div class="cell">
  @if (edit) {
<div class="jupyter-controls">
    <app-btn [mini]="true" [icon]="'add'" (click)="prependCell('TEXT')" [text]="'Text'" type="secondary">
    </app-btn>
    <app-btn [mini]="true" [icon]="'add'" (click)="prependCell('TABLE')" [text]="'Table'" type="secondary">
    </app-btn>
    <app-btn [mini]="true" [icon]="'add'" (click)="prependCell('UPLOAD')" [text]="'Upload'" type="secondary">
    </app-btn>
  </div>
}
  <div class="cell-delete">
    @if (edit) {
<app-btn [group]="editing ? 'show' : 'edit'" [text]="editing ? 'Preview' : 'Edit'" (click)="editing = !editing"
     >
    </app-btn>
}
    @if (edit) {
<app-btn [group]="'delete'" (click)="deleteCell(index)"/>
}
  </div>
  <div class="jupiter-cell">
    @if (edit) {
<div class="input-cell" [ngClass]="{'hidden': !editing}">
      <ng-content select="[jupiter-cell-input]"></ng-content>
    </div>
}
    <div class="output-cell" [ngClass]="{'hidden': edit ? editing : false}">
      <ng-content select="[jupiter-cell-output]"></ng-content>
    </div>
  </div>
  @if (edit) {
<div class="jupyter-controls">
    <app-btn [mini]="true" [icon]="'add'" (click)="appendCell('TEXT')" [text]="'Text'" type="secondary">
    </app-btn>
    <app-btn [mini]="true" [icon]="'add'" (click)="appendCell('TABLE')" [text]="'Table'" type="secondary">
    </app-btn>
    <app-btn [mini]="true" [icon]="'add'" (click)="appendCell('UPLOAD')" [text]="'Upload'" type="secondary">
    </app-btn>
  </div>
}
</div>
