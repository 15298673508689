import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of } from 'rxjs';
import { TableCol } from '@Shared/models/index.model';
import { TablePlainComponent } from 'ets-fe-ng-sdk';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { InputBasicComponent } from 'ets-fe-ng-sdk';
import { NgFor } from '@angular/common';
import { InputLabelComponent } from 'ets-fe-ng-sdk';

/**
 * @Reusable A component that allows free configuration of columns in a
 * user input table. - Only supports text inputs at the moment
 */
@Component({
    selector: 'app-create-table',
    templateUrl: './create-table.component.html',
    styleUrls: ['./create-table.component.scss'],
    standalone: true,
    imports: [
        InputLabelComponent,
        NgFor,
        InputBasicComponent,
        BtnComponent,
        TablePlainComponent,
    ],
})
export class CreateTableComponent {
  dCols: TableCol[] = [
    {
      t: '',
      f: 'add',
      type: 'button',
      icon: 'add',
      action(row, cellField, setLoading, index) {
        const parent = row.parent as FormArray;
        parent.insert(index, new FormGroup({}));
      },
    },
  ];
  dynamicFormGroup: FormGroup;
  form = new FormGroup({
    inputs: new FormArray([]),
    data: new FormArray([]),
  });
  fields: any[] = [];
  @Input() returnedFields: AbstractControl;
  @Input() control: AbstractControl;

  /**
   * Dynamically create form group
   * @param model Array of config names for the form controls
   * @param fields Array to set field names
   * @param r Value to patch into the formgroup
   * @returns FormGroup
   */
  createFormControlFields(model: string[], fields?: any[], r?: any) {
    const formGroupFields = new FormGroup({});
    for (const field of model) {
      formGroupFields.addControl(field, new FormControl());
      fields?.push(field);
    }
    r && formGroupFields.patchValue(r);
    return formGroupFields;
  }

  ngOnInit() {
    this.initializeEdit();
    this.data.patchValue(this.control.value);
    this.data.valueChanges.subscribe((v) => {
      this.control?.setValue(v);
      this.returnedFields.setValue(this.dCols.slice(0, -2));
    });
    this.inputs.valueChanges.subscribe(() => {
      this.returnedFields.setValue(this.dCols.slice(0, -2));
    });
  }
  /**
   * Dynamically edit based on provided
   * config and data
   */
  initializeEdit() {
    if (this.returnedFields.value?.length) {
      this.inputs.clear();
      this.returnedFields.value.forEach((field) => {
        this.inputs.push(this.createControl(field.t));
      });
      this.setColumns();
    }
    if (this.control.value?.length)
      this.control.value.forEach((value) => {
        this.addRow();
      });
  }

  constructor() {
    this.addControl();
  }

  get model() {
    return this.inputs.value.map((s) =>
      s['header'].trim().split(' ').join('_').toLowerCase()
    );
  }

  get inputs() {
    return this.form.get('inputs') as FormArray;
  }

  get data() {
    return this.form.get('data') as FormArray;
  }

  /**
   * Used to create an observable, for autorefresh of table
   */
  get rows() {
    return of(this.data.controls);
  }

  private createControl(v: string = ''): FormGroup {
    return new FormGroup({
      header: new FormControl(v, Validators.required),
    });
  }

  /**
   * Command bound to the add column button
   * Adds a field on the input and creates a column
   */
  addControl(i?: number) {
    if (!i) this.inputs.push(this.createControl());
    if (i) this.inputs.insert(i + 1, this.createControl());
    this.setColumns();
  }

  /**
   * Delete a column or reset table
   * @param index Index of column
   */
  removeColumn(index: number) {
    if (this.inputs.length > 1) this.inputs.removeAt(index);
    else this.inputs.reset();
    this.setColumns();
  }

  /**
   * Adds a row on the table
   */
  addRow() {
    this.data.push(new FormGroup({}));
    this.configureRows();
  }

  /**
   * Sets the control values
   * for each row
   */
  configureRows() {
    const v = this.data.value;
    this.data.clear();
    v.forEach((r) => {
      this.data.push(this.createFormControlFields(this.model, [], r));
    });
  }

  /**
   * Sets the table columns based on form config
   */
  setColumns() {
    let m = this.model;
    let s = this.createFormControlFields;
    this.dCols = [];
    this.inputs.controls.forEach((control) => {
      let value: string = control.value.header;
      if (!value) return;
      this.dCols.push({
        t: value,
        f: value.trim().split(' ').join('_').toLowerCase(),
        type: 'input',
      });
    });
    this.dCols.push({
      t: '',
      f: 'add',
      type: 'button',
      icon: 'add',
      action: (row, cellField, setLoading, index) => {
        const parent = row.parent as FormArray;
        parent.insert(index+1, s(m));
      },
    });
    this.dCols.push({
      t: '',
      f: 'delete',
      type: 'button',
      icon: 'delete',
      action: (row, cellField, setLoading, index) => {
        const parent = row.parent as FormArray;
        parent.removeAt(index);
      },
    });
  }
}
